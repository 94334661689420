import { render, staticRenderFns } from "./SectionImageSide.vue?vue&type=template&id=42ea0028&"
import script from "./SectionImageSide.vue?vue&type=script&lang=js&"
export * from "./SectionImageSide.vue?vue&type=script&lang=js&"
import style0 from "./SectionImageSide.vue?vue&type=style&index=0&id=42ea0028&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
